












































































































































































.table::v-deep {
  position: relative;
  .v-data-table__wrapper {
    padding-bottom: 40px;
    table {
      // min-width: 320px !important;
      // max-width: 320px !important;
      // width: 320px !important;
      // overflow: hidden;

      th {
        color: #FFF !important;
        font-weight: bold;
        font-size: 15px;
        text-align: right;
        background: #57d3ff;
        // color: #FFF;
      }

      thead {
        tr {
          box-shadow: 1px 1px 5px rgba(0, 0, 0, .41) !important;

        }
      }

      tr  {

        > th {
          // border-bottom: 1px solid #000 !important;
          

        }
      }

      tbody {
        tr {
          background-color: rgb(221, 221, 221, 0.25);

          &:nth-child(2n) {
            background-color: rgb(238, 238, 238, .25);
          }

          td {
            padding: 10px 5px;
            color: #5076ff;
            font-size: 14px;

            .isPositive {
              color: #0d7612;
            }
          }

          &:last-child {
            position: absolute;
            bottom: -10px;
            width: 100%;
            background-color: #FFF;
            display: flex;
            justify-content: flex-end;
            padding-right: 12px;
            td {
              color: #333 !important;
              flex-shrink: 0;
              height: auto;
              font-weight: bold;

              &:first-child {
                width: 100%;
                flex-shrink: 1;
              }
            }
          }
        }
      }
    }
  }
}

.v-card__text::v-deep {
  .v-data-table__wrapper {
    max-height: 613px;
  }
}

.v-card__actions {
  padding: 16px;
  display: block;
}

.box-title {
  font-size: 18px;
  padding-bottom: 0;
}

.v-card__text {
  padding-top: 0;
}
